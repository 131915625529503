<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20">
    <path
      :style="iconStyle"
      d="M11,15h2v2H11Zm0-8h2v6H11Zm.99-5A10,10,0,1,0,22,12,10,10,0,0,0,11.99,2ZM12,20a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
      transform="translate(-2 -2)"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '20',
    },
    width: {
      type: String,
      default: '20',
    },
    color: {
      type: String,
      default: 'primary-green',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
